<template>
  <div class="page-box">
    <div class="meeting-box">
      <a v-for="(item,index) in dataList1" :key="index" :href="item.url" target="_blank" class="meeting-img2">
           <img style="width:100%;" :src="item.img"/>
      </a>
      <div class="bottom-box">
        <a v-for="(item,index) in dataList" :key="index" :href="item.url" target="_blank" class="meeting-img1">
             <img style="width:100%;" :src="item.img"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["language"],
  data() {
    return {
      dataList: [
        {
          img: require("../../assets/images/special2-4.png"),
          url: "http://live.skyfar.net/live/1655423556542062594",
        },
      ],
      dataList1: [
        {
          img: require("../../assets/images/special2-1.png"),
          url: "http://live.skyfar.net/live/1655418732823973889",
        },
        {
          img: require("../../assets/images/special2-2.png"),
          url: "http://live.skyfar.net/live/1655421765041577985",
        },
        {
          img: require("../../assets/images/special2-3.png"),
          url: "http://live.skyfar.net/live/1655422929539751937",
        },
      ],
    };
  },
};
</script>

<style lang='less'>
.page-box {
  width: 100%;
  min-width: 1200px;
  aspect-ratio: 1903/1372;
  // min-height: 100vh;
  background-image: url("../../assets/images/meettingBg2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 15.7%;
    box-sizing: border-box;
    background-color: #001532;
  .meeting-box {
    margin-top: 55vh;
      width: 63.02%;
      min-width: 1200px;
    //   height: 37.02%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .meeting-img1{
      width: 53%;
      margin-top: 1.5vh;
      aspect-ratio: 295/255;
  }
  .meeting-img2{
      width: 33.3%;
      aspect-ratio: 402/249;
  }
  .modle-box {
    width: 22.08%;
    min-width: 190px;
    height: 23vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 10px 9px #19356e;
    border: 3px solid #2a3b91;
    background-color: #151955;
    border-radius: 10px;
  }
  .meeting-title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .meeting-time {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;
  }
    .modle-box-bottom {
    width: 30%;
    min-width: 210px;
    height: 24vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 10px 9px #19356e;
    border: 3px solid #2a3b91;
    background-color: #151955;
    border-radius: 10px;
    margin-top: 50px;
  }
  .bottom-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>